import React from 'react'

const SocialNav = () => {
  return (
    <nav className="navbar navbar-default social-nav">
      <div id="navbar" className="ml-auto mr-auto mt-3">
        <ul className="navbar-nav">
          <li className="nav-social-link">
            <a
              href="https://dribbble.com/Hidjou"
              target="_blank"
              rel="noopener noreferrer"
              className="dribbble"
            >
              <i className="fab fa-dribbble fa-2x nav-social-link" />
            </a>
          </li>
          <li className="nav-social-link">
            <a
              href="https://github.com/hidjou"
              target="_blank"
              rel="noopener noreferrer"
              className="github"
            >
              <i className="fab fa-github fa-2x nav-social-link" />
            </a>
          </li>
          <li className="nav-social-link">
            <a
              href="https://www.linkedin.com/in/ahmed-hadjou-a7846885/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              <i className="fab fa-linkedin fa-2x nav-social-link" />
            </a>
          </li>
          <li className="nav-social-link">
            <a
              href="https://twitter.com/TheHidjou"
              target="_blank"
              rel="noopener noreferrer"
              className="twitter"
            >
              <i className="fab fa-twitter fa-2x nav-social-link" />
            </a>
          </li>
          <li className="nav-social-link">
            <a
              href="https://www.facebook.com/hidjou"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook"
            >
              <i className="fab fa-facebook fa-2x nav-social-link" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default SocialNav
