import React from 'react'
import { Link } from 'gatsby'

class NavLink extends React.Component {
  constructor() {
    super()
    this.state = {
      isActive: false,
    }
  }
  componentDidMount() {
    if (
      window.location.pathname === this.props.to ||
      window.location.pathname.includes(this.props.to + '/')
    ) {
      this.setState({
        isActive: true,
      })
    }
  }
  render() {
    var className = this.state.isActive ? 'nav-link active' : 'nav-link'
    return (
      // for blog to be active on a blog post check if pathname INCLUDES props.to
      // console.log(window);
      <Link className={className} {...this.props}>
        {this.props.children}
      </Link>
    )
  }
}

export default NavLink
