import React from 'react';

export default () => {
  return (
    <div>
      <hr className="footer-horizontal-ruler" />
      <footer className="custom-footer">
        <p>Copyright © {new Date().getFullYear()} Ahmed Hadjou</p>
      </footer>
    </div>
  );
};
