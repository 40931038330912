import React, { Component } from 'react'
import NavLink from './NavLink'

class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-sm navbar-default main-nav">
          {/* <Link to="/" className="navbar-brand">
            Ahmed Hadjou
          </Link> */}
          <button
            type="button"
            className="navbar-toggler upar collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <div id="navbar" className="collapse navbar-collapse">
            <ul className="navbar-nav ml-auto mr-auto">
              <li className="nav-item">
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/work">Work</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about">About</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default Navbar
